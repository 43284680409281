<template>
    <div>
        <no-data-dialog v-if="noDataDialogLocal" @input="noDataDialogLocal = false" />
        <v-btn
            color="quaternary"
            dark
            class="ml-2 mb-2 float-right"
            @click="$emit('download')">
            Export to Excel
        </v-btn>
        <v-dialog
            v-model="addDialogLocal"
            max-width="500px"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            persistent
            @keydown.esc="addDialogLocal = false">
            <template v-slot:activator="{ on }">
                <v-btn
                    v-if="canEdit(claimName) && !isAddDisabled"
                    color="primary"
                    dark
                    class="ml-2 mb-2 float-right"
                    v-on="on"
                    @click="$emit('addItem')">
                    Add {{ formTitle }}
                </v-btn>
            </template>
            <slot name="form" />
        </v-dialog>
    </div>
</template>

<script>
export default {
    components: {
        noDataDialog: () => import("@/export/NoDataDialog.vue"),
    },
    props: {
        addDialog: {
            type: Boolean,
            default: false,
        },
        noDataDialog: {
            type: Boolean,
            default: false,
        },
        formTitle: {
            type: String,
            default: null,
        },
        claimName: {
            type: String,
            default: null,
        },
        isAddDisabled: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        addDialogLocal: {
            get() {
                return this.addDialog;
            },
            set(addDialog) {
                this.$emit("update:addDialog", addDialog);
            },
        },
        noDataDialogLocal: {
            get() {
                return this.noDataDialog;
            },
            set(noDataDialog) {
                this.$emit("update:noDataDialog", noDataDialog);
            },
        },
    },
};
</script>
